export const theme = {
  color: {
    light: {
      white: '#fff',
      offWhite: '#f5f5f5',
      black: '#222',
      lightGrey: '#ececec',
      grey: '#dddddd',
      darkGrey: '#aaaaaa',
      background: '',
      primary: '',
      primaryAccent: '#f66d6d',
      brand: '',
      brandAccent: '',
      warning: 'orange',
      warningAccent: '',
      info: 'blue',
      infoAccent: 'blue',
      danger: 'red',
      dangerAccent: 'red',
      succes: '',
      succesAccent: '',
    },
    dark: {
      background: '',
    }
  },
  breakPoints: {
    XSMin: '539px',
    XSMax: '540px',
    SMin: '779px',
    Smax: '780px',
    MMin: '979px',
    MMax: '980px',
    LMin: '1199px',
    LMax: '1200px',
    XLMin: '1440px',
  }
}