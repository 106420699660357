import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/Seo'

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
  </Layout>
)

export default SecondPage
