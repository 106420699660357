import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import { StyledHeader } from './styled'

const Header = () => (
  <StyledHeader>
    Hello
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Belxit`,
}

export default Header
